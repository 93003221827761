
// Vue core
import { computed, onMounted, reactive, ref, watch } from 'vue';

// icons
import { navigateOutline, personCircle, arrowBack, close, checkmark, options, } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonCol, IonRow, IonButtons, IonButton, IonBackButton,
        IonCard, IonCardTitle, IonCardHeader, IonCardContent, IonNote,
        IonItem, IonLabel, IonInput, IonTextarea, IonDatetime, IonAccordionGroup, IonAccordion,
        IonSelect, IonSelectOption, IonIcon, IonSpinner, IonReorderGroup, IonReorder, IonList, IonCheckbox,
        loadingController, alertController, onIonViewDidEnter, onIonViewWillLeave, modalController, } from '@ionic/vue';
import SchoolSelectModal from '@/components/secondary/SchoolSelectModal.vue';
import ABDisciplineSelectModal from '@/components/pss/ABDisciplineSelectModal.vue';
import RoleInSchoolSelectModal from '@/components/teacher/RoleInSchoolSelectModal.vue';
import RolesInClassesModal from '@/components/teacher/RolesInClassesModal.vue';

// firebase
import firebase from 'firebase/compat/app';
import { auth, credentialSignIn, phoneSignIn, customTokenSignin, } from '@/auth';
//import { cfaSignInPhone, cfaSignInPhoneOnCodeSent } from 'capacitor-firebase-auth'; // for fixing iOS reCAPTCHA bug
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';


// utils
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';
import { useRoute, useRouter } from 'vue-router';
import config from '@/config';

// Services
import AuthService from '@/services/AuthService';
import UserService from '@/services/UserService';
import TeacherService from '@/services/TeacherService';

// types
import { Discipline, School, Service, Session, User } from '@/types';

export default {
  name: 'RegisterPage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
                IonGrid, IonCol, IonRow, IonButtons, IonButton, IonBackButton,
                IonCard, IonCardTitle, IonCardHeader, IonCardContent, IonNote,
                IonItem, IonLabel, IonInput, IonTextarea, IonDatetime, IonAccordionGroup, IonAccordion,
                IonSelect, IonSelectOption, IonIcon, IonSpinner, IonReorderGroup, IonReorder, IonList, IonCheckbox, },
  setup() {
    const { presentPrompt, uniqueId, getF4YearDSE, getF5YearDSE, getProxyImgLink, getLinkifiedText,
            openModal, openImageModal, openServiceModal, presentToast, getSecStudentForm, } = utils();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const settings = computed(() => store.state.settings);
    const loading = computed(() => store.state.loadingData);
    const schools = computed<School[]>(() => store.state.schools);
    const user = computed<User>(() => store.state.user); // logged in user
    const groupedUpcomingEvents = computed(() => store.getters.groupedUpcomingWorkEvents);
    const upcomingUCircleEvents = computed(() => store.getters.getUpcomingEventsByGroup('UCircle'));
    const upcomingTeacherEvents = computed(() => store.getters.getUpcomingEventsByGroup('Teacher Event'));
    const selectedEvents = ref<Session[]>([]);

    // Prefilled links / params
    const { schoolId: routeSchoolId, eventId: routeEventId, programId: routeProgramId, group: routeStudentGroup, ucirclerPhone, serviceId: prefilledServiceId } = route.params;
    const { schoolId: querySchoolId, e: queryEventId, phone: prefilledPhone, role: prefilledRole, p: queryProgramId, noVerifyPhone } = route.query;
    const prefilledProgramId = routeProgramId || queryProgramId; // university program
    const prefilledSchoolId = routeSchoolId || querySchoolId; // secondary school
    let prefilledEventId = routeEventId || queryEventId;
    const featuredEvent = ref<Session>();
    const featuredEventService = ref<Service>();
    const featuredService = computed<Service>(() => store.getters.getServiceById(routeStudentGroup == 'ucircle' ? 'ucircle' : prefilledServiceId)); // e.g. HealthcareWork

    const registeringAccount = ref(false);
    const recaptchaDiv = ref<any>(null);
    const fullNameInput = ref(null);
    const preferredNameInput = ref(null);
    const chineseNameInput = ref(null);
    const phoneInput = ref(null);
    const emailInput = ref(null);
    const pswInput = ref(null);
    const confirmPswInput = ref(null);
    const studentNumberInput = ref(null);

    const getPrefilledRole = () => {
      if (route.path.startsWith("/regevent")) return "";
      if (route.path.startsWith("/t") || prefilledServiceId) return "teacher";
      if (route.path.startsWith("/u") || prefilledProgramId) return "university-student";
      if (prefilledRole) return prefilledRole;
      //if (prefilledSchoolId || route.path.startsWith("/s")) return "secondary-school-student";
      if (route.path.startsWith("/s")) return "secondary-school-student";
      if (route.path.startsWith("/regapply")) return "secondary-school-student";
      return "";
    }
    const application = reactive({
      schoolName: "",
      schoolId: prefilledSchoolId?.toString().replace("{{s}}", ""),
      fullName: "",
      chineseName: "",
      preferredName: "",
      gender: "",
      phone: (ucirclerPhone || prefilledPhone)?.toString().replace("{{p}}", "") ,
      email: "",

      // Secondary Student
      step1Enabled: false,
      absIntakeId: "",
      class: "",
      studentNumber: "",
      studyingCurriculum: "HKDSE",
      studyingElectives: [],
      yearDSE: "",
      rankInForm: "",
      interestedDisciplines: [],
      interestedDisciplineIds: [],
      interestedDisciplineNames: "",
      userDisciplines: [],

      // Teacher
      roleInSchool: [],
      rolesInClasses: [],
      prefilledServiceId,
      prefilledEventId, // for records

      // University Student
      programId: prefilledProgramId || "", // jobEX program (e.g. MM jobEX)
      prefilledProgramId,
      uniEmail: "",
      studentId: "",
      studentStatus: "", // nationality
      yearOfStudy: "", // Year 1 / 2 / 3 / 4
      studyingStream: "", // Specialism
      gradYear: "",
      howToKnow: "",
      referrer: "",

      // University client
      institution: "", // text input
      position: "", // text input

      // General
      roles: getPrefilledRole(),
      isPhoneVerified: true,

      // For UCircle
      //wantToApplyUCircle: routeStudentGroup == 'ucircle' ? "Yes" : "",
      group: routeStudentGroup || "",
      q1Ans: "",
      q2Ans: "",
      q3Ans: "",
      isPrefect: null,
      referrerRole: routeStudentGroup == 'ucircle' ? "" : "I will not join UCircle.",
      referrerName: "",
      referrerPhone: "",

      // TBD
      password: "",
      confirmPsw: "",

      showReferrerInput: route.path.startsWith("/regapply"),
    });
    const isUCircleApplicant = () => (application.referrerRole && application.referrerRole != 'I will not join UCircle.');

    const syncSchoolDetails = (schoolId) => {
      const school =  schools.value.find(s => s.id == schoolId);
      if (school) {
        application.schoolName = `[${school.nameShort}] ${school.name}`;
        application.step1Enabled = school.step1Enabled;
        application.absIntakeId = school.absIntakeId;
      }
    }
    const prefillRegFormDetails = (res: User) => {
      watch(schools, () => {
        syncSchoolDetails(res.schoolId);
      })
      syncSchoolDetails(res.schoolId);
      application.schoolId = res.schoolId;
      application.fullName = res.fullName || "";
      application.chineseName = res.chineseName || "";
      application.preferredName = res.preferredName || "";
      application.gender = res.gender || "";
      application.phone = res.phone || "";
      application.email = res.email || "";
      application.class = res.class || "";
      application.studentNumber = res.studentNumber || "";
      application.studyingCurriculum = res.studyingCurriculum || "";
      const electives: any = Array.isArray(res.studyingElectives) ? res.studyingElectives : (res.studyingElectives?.split(" , ") || []).filter(e => e);
      application.studyingElectives = electives;
      application.yearDSE = res.yearDSE || "";
      application.rankInForm = res.rankInForm || "";
    }
    if (ucirclerPhone) {
      UserService.getUserByPhone(ucirclerPhone).then((res: User) => {
        if (res) prefillRegFormDetails(res);
      });
    }

    const isSecondaryStudent = computed(() => (application.roles == "secondary-school-student"));
    const isTeacher = computed(() => (application.roles == "teacher"));
    const isPrincipal = computed(() => (application.roleInSchool.some(r => ["Principal", "Vice Principal"].includes(r))));
    const isUniversityStudent = computed(() => (application.roles == "university-student"));
    const isUniversityClient = computed(() => (application.roles == "university-client"));

    const window: any = {
      recaptchaVerifier: undefined,
      confirmationResult: undefined,
      verificationId: undefined, // for iOS
    };
    const resetRecaptchaVerifier = () => {
      if (window.recaptchaVerifier) window.recaptchaVerifier.clear();
      if (recaptchaDiv.value) recaptchaDiv.value.innerHTML = '<div id="grecaptcha-div"></div>';
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('grecaptcha-div', {
        'size': 'invisible',
        'callback': (res: any) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        }
      });
    }

    // University Students
    const allJobEXes = computed(() => store.getters.activeJobEXes);
    const jobEXProgrammes = computed(() => store.getters.jobEXProgrammes);
    const selectedJobEX = () => (allJobEXes.value.find((jobEX: any) => jobEX.relatedProgramIds.includes(application.programId)) || {});
    const selectedJobEXIntakeEvents = () => {
      const relatedIntakeId = selectedJobEX()['intakes'] ? selectedJobEX()['intakes'][0]?.id : null;
      return relatedIntakeId ? store.state.sessions.filter(ev => {
        return ev.group == "jobEX" && ev.jobEXIntakeId == relatedIntakeId && (new Date(ev.startTimeStr) > new Date(+new Date() - 86400000) && ev.anchorEventId != "jobex-consultation-session")
      }) : [];
    }

    // methods
    const { t } = useI18n();
    const { presentAlert, presentVerifyCodeInputAlert, focusInputField, iPhoneNativeApp, } = utils();

    /**
     * Registration Functions
     */
    const setupUserAccount = (userId, existingFirebaseUser: any = null, verificationCode = "") => {
      if (application.roles == "teacher") application.yearDSE = "";
      application.class = (application.class || "").toUpperCase(); // make class uppercase
      store.commit('updateUser', { id: userId, ...application });
      store.commit('receiveUserFormResponses', []); // always empty array for new users

      const filteredEvents = selectedEvents.value.filter(ev => {
        if (application.roles == "teacher" && ev.id == prefilledEventId) {
          if (ev.group != "Teacher Event") return false; // not allow teachers applying students' events
        }
        return isUniversityStudent.value || ev.targetDSEYears.length == 0 || ev.targetDSEYears.includes(application.yearDSE);
      });
      const userEventResponses = filteredEvents.map(ev => ({
        id: `r${uniqueId()}`,
        sessionId: ev.id,
        eventName: ev.displayName,
        eventDateTime: ev.formattedDateTime,
        response: 'Yes',
        confirmed: 'Yes',
      }));
      store.commit('receiveUserEventResponses', userEventResponses);

      // for showing acknowledgment after registration
      if (prefilledEventId) {
        const ackAppliedEvent = filteredEvents.find(ev => ev.id == prefilledEventId);
        store.commit('receiveAckAppliedEvent', ackAppliedEvent);
      }
      if (prefilledServiceId) {
        store.commit('receiveAckInterestedService', featuredService);
      }

      const payload = {
        uid: userId,
        ...application,
        userEventResponses,
        existingFirebaseUser,
        intakeId: selectedJobEX()['intakes'] ? selectedJobEX()['intakes'][0].id : "", // jobEX student
        jobEXName: selectedJobEX()['id'], // jobEX student
        myCredentialsSections: config.cvSections, // jobEX student
      }
      UserService.createNewUser(payload).then(newUser => {
        if (verificationCode == 'skip') newUser.userInWaGroup = true; // users cannot join WhatsApp group with iPad
        if (Array.isArray(newUser.teacher)) newUser.teacher = newUser.teacher[0] || newUser.teacher; // should be object directly
        store.commit('receiveUser', { id: userId, ...newUser });
        store.dispatch('getPortalData', application.programId);

        // Register for specific service; store the preference for follow-up
        if (prefilledServiceId && newUser.teacher) {
          const teacherResponse = {
            intakeYear: featuredService.value?.currIntakeYear,
            eventName: "",
            type: 'service',
            response: "I am interested in this service. Please provide me with more details via WhatsApp.",
          };
          TeacherService.upsertTeacherResponse(null, prefilledServiceId, teacherResponse, newUser.phone, newUser.schoolId, newUser.id).then(responseObj => {
            store.commit('upsertTeacherResponse', { ...responseObj, updatedAt: new Date().toString() });
          });
        }

        // Check if WhatsApp group link is available
        const checkUserInterval = setInterval(() => {
          if (store.state.user.waGroupLink) {
            clearInterval(checkUserInterval);
          } else {
            UserService.getLoggedInUser().then(res => {
              store.commit('receiveUser', res);
              //if (res.sessionResponses) store.commit('receiveUserEventResponses', res.sessionResponses);
              clearInterval(checkUserInterval);
            });
          }
        }, 20000);

        // Get user WhatsApp group (according to row number)
        if (!isUniversityClient.value) { // skip if university client
          UserService.getNewUserWAGroup(newUser.row).then(res => {
            const { id: waGroupId, inviteLink: waGroupLink } = res;
            if (waGroupId) {
              store.commit('updateUser', { waGroupId, waGroupLink });
            }
          });
        }
      });

      if (user.value.id) { // Already logged in
        //router.replace('/profile');
        loadingController.dismiss();
      }
      if (isUCircleApplicant()) presentToast("You have submitted your application for UCircle successfully!", 5000, 'middle');
    }
    const verifyCode = async (verificationCode: any, existingFirebaseUser: firebase.UserInfo) => {
      const loading = await loadingController.create({ message: t('RegisterPage.registeringAccount') });
      await loading.present();
      try {
        let res: any = {};
        if (verificationCode == 'skip') { // if users log in with iPad
          alertController.dismiss();

          application.isPhoneVerified = false;
          res = await AuthService.createFirebaseAuthUser(application.phone);
          await customTokenSignin(res.token);
        } else {
          if (iPhoneNativeApp()) {
            const phoneCredential = firebase.auth.PhoneAuthProvider.credential(window.verificationId, verificationCode);
            res = await credentialSignIn(phoneCredential);
          } else {
            res = await window.confirmationResult.confirm(verificationCode);
          }
        }
        setupUserAccount(res.user?.uid, !!existingFirebaseUser, verificationCode);

        loading.dismiss();
        alertController.dismiss();

        registeringAccount.value = false;
      } catch (e: any) {
        registeringAccount.value = false;
        loading.dismiss();
        if (e.code == "auth/invalid-verification-code") { // wrong verification code
          presentAlert(t('RegisterPage.invalidVerificationCode'))
        } else {
          presentAlert(e.message);
        }
      }
    }
    const presentCodeInputAlert = async (phone: any, existingFirebaseUser: firebase.UserInfo) => {
      await presentVerifyCodeInputAlert(phone, (verificationCode: any) => {
        verifyCode(verificationCode, existingFirebaseUser);
      }, true);
      //}, !existingFirebaseUser);
    }
    const doRegister = async () => {
      presentPrompt(t('confirmSubmit'), async () => {
        //application.group = (application.wantToApplyUCircle == 'Yes' ? "ucircle" : "");
        application.group = (isUCircleApplicant() ? "ucircle" : "");
        const { phone, } = application;
        if (phone) {
          if (/^\d{8}$/.test(phone) === false) return presentAlert(t('RegisterPage.enterValidHKMobileNumber'));
          const loading = await loadingController.create({ duration: 30000 });
          await loading.present();

          if (user.value.id) { // Already logged in (no need phone verification)
            setupUserAccount(user.value.id, true, "");
          }
          else { // Phone verification required
            //const timeoutHandler = setTimeout(resetRecaptchaVerifier, 30000);
            registeringAccount.value = true;
            try {
              // 1. Check if phone already exists in our database
              const existingFirebaseUser = await AuthService.checkPhoneExists(phone);

              // 2. Verify the phone number (SMS code)
              const verifyingPhone = `+852${phone}`;
              if (noVerifyPhone == '1') verifyCode('skip', existingFirebaseUser); // skip verifiying number
              else {
                if (iPhoneNativeApp()) { // native iOS APP
                  const res: any = await FirebaseAuthentication.signInWithPhoneNumber({ phoneNumber: verifyingPhone });
                  loading.dismiss();
                  window.verificationId = res.verificationId;
                  presentCodeInputAlert(phone, existingFirebaseUser);
                  /*
                  cfaSignInPhone(verifyingPhone).subscribe((user) => {
                    console.log(user);
                  })
                  cfaSignInPhoneOnCodeSent().subscribe((verificationId) => {
                    window.verificationId = verificationId;
                    presentCodeInputAlert(phone, existingFirebaseUser);
                  })
                    */
                  //clearTimeout(timeoutHandler);
                } else { // Web App
                  if (settings.value.smsVerification == "FALSE") { // https://docs.google.com/spreadsheets/d/1MRnZtfwR6THzVaRB38Es0RGWhYloPdYJB-n7iC6qQSg/edit?gid=483238421#gid=483238421
                    verifyCode('skip', existingFirebaseUser); // for mass registration
                  } else {
                    const appVerifier = window.recaptchaVerifier;
                    const confirmationResult = await phoneSignIn(verifyingPhone, appVerifier);
                    window.confirmationResult = confirmationResult;
                    presentCodeInputAlert(phone, existingFirebaseUser);
                  }
                }
              }
              registeringAccount.value = false;
            } catch (e: any) {
              registeringAccount.value = false;
              loadingController.dismiss();
              //clearTimeout(timeoutHandler);
              resetRecaptchaVerifier();
              if (e.code == "auth/invalid-phone-number") {
                presentAlert(t('RegisterPage.enterValidHKMobileNumber')); // invalid phone number
              } else {
                presentAlert(e.message);
              }
            }
          }
        } else {
          presentAlert(t('RegisterPage.enterPhoneAndPassword'));
        }
      })
    }
    onIonViewDidEnter(() => {
      setTimeout(resetRecaptchaVerifier, 200);
      if (user.value.id) prefillRegFormDetails(user.value); // student already logged in
    });
    onIonViewWillLeave(() => {
      //if (window.recaptchaVerifier) window.recaptchaVerifier.clear();
      if (recaptchaDiv.value) {
        recaptchaDiv.value.innerHTML = '<div id="grecaptcha-div"></div>';
      }
    })

    // Logged in user (ucircle form)
    watch(user, (curr) => {
      if (curr && curr.id) prefillRegFormDetails(curr); // student already logged in
    })

    // Prefill School
    if (prefilledSchoolId) {
      watch(schools, (curr) => {
        syncSchoolDetails(prefilledSchoolId);
      })
    }
    // Prefill Work Event
    if (prefilledEventId) {
      if (prefilledEventId.toString().toLowerCase() == 'econwork') prefilledEventId = "e7b060c3";
      if (prefilledEventId.toString().toLowerCase() == 'businesswork') prefilledEventId = "e071b0aa";

      watch(groupedUpcomingEvents, (curr) => {
        for (const key in curr) {
          const events: any = curr[key];
          for (const ev of events) {
            if (ev.id == prefilledEventId) {
              selectedEvents.value.push(ev);
              featuredEvent.value = ev;
              console.log(ev.serviceId);
              featuredEventService.value = store.getters.getServiceById(ev.serviceId);
              console.log('Featured Service', featuredEventService.value);
              application.yearDSE = (ev.targetDSEYears[0] || getF5YearDSE()).toString();
              break;
            }
          }
        }
      })

      watch(upcomingTeacherEvents, (curr) => {
        for (const ev of curr){
          if (ev.id == prefilledEventId){
            selectedEvents.value.push(ev);
          }
        }
      })
    }

    let selectModalOpened = false;

    return {
      // icons
      navigateOutline, personCircle, arrowBack, close, checkmark,

      // variables
      settings, loading,
      OPTIONS: config.formOptions,
      user, // logged in user
      application, registeringAccount,
      recaptchaDiv,
      phoneInput, emailInput, pswInput, confirmPswInput,
      fullNameInput, preferredNameInput, chineseNameInput,
      groupedUpcomingEvents, selectedEvents,
      studentNumberInput,
      prefilledEventId,

      isSecondaryStudent, isTeacher, isPrincipal,
      isUniversityStudent, isUniversityClient,

      // methods
      getProxyImgLink,
      openRoleInSchoolSelectModal : async () => {
        if (!selectModalOpened) {
          selectModalOpened = true;
          const modal = await modalController.create({
            component: RoleInSchoolSelectModal,
            componentProps: { selectedRolesInSchool: application.roleInSchool },
          });
          modal.onDidDismiss().then(({ data }) => {
            if (data && data.workingSelectedValues) {
              application.roleInSchool = data.workingSelectedValues.value;
            }
            selectModalOpened = false;
          });
          return modal.present();
        }
      },
      openRolesInClassesModal: async () => {
        if (!selectModalOpened) {
          selectModalOpened = true;
          const modal = await modalController.create({
            component: RolesInClassesModal,
            componentProps: { prefilledRolesInClasses: application.rolesInClasses },
          });
          modal.onDidDismiss().then(({ data }) => {
            if (data && data.rolesInClasses) {
              application.rolesInClasses = data.rolesInClasses;
            }
            selectModalOpened = false;
          });
          return modal.present();
        }
      },

      t, focusInputField, doRegister,
      featuredEvent, featuredEventService, openImageModal, openServiceModal,
      getF4YearDSE, getF5YearDSE, getSecStudentForm,
      onCheckWorkEvent: (checked: any, workEvent: Session) => {
        if (checked) {
          if (selectedEvents.value.find(e => e.id == workEvent.id) == null) {
            selectedEvents.value.unshift(workEvent);
          }
        }
        else {
          const idx = selectedEvents.value.findIndex(p => p.id == workEvent.id);
          if (idx !== -1) selectedEvents.value.splice(idx, 1);
        }
      },

      openDisciplineSelectModal: async () =>  {
        if (!selectModalOpened) {
          selectModalOpened = true;
          const modal = await modalController.create({
            component: ABDisciplineSelectModal,
            componentProps: {
              prefilledDisciplines: application.interestedDisciplines.slice(),
              oldUserDisciplines: application.userDisciplines?.slice() || [],
              fromRegForm: true,
            }
          });
          modal.onDidDismiss().then(({ data }) => {
            if (data && data.chosen) {
              application.interestedDisciplines = data.chosen;
              application.interestedDisciplineIds = data.chosen.map(d => d.id);
              application.interestedDisciplineNames = data.chosen.map(d => d.name).join(" , ");
              application.userDisciplines = data.userDisciplines;
            }
            selectModalOpened = false;
          });
          return modal.present();
        }
      },
      openSchoolSelectModal: async () => {
        if (!selectModalOpened) {
          selectModalOpened = true;
          const modal = await modalController.create({
            component: SchoolSelectModal,
            componentProps: { schools: schools.value }
          });
          modal.onDidDismiss().then(({ data }) => {
            if (data && data.selectedSchool) {
              application.schoolName = data.selectedSchool;
              application.schoolId = data.schoolId || data.selectedSchool;
              const school =  schools.value.find(s => s.id == application.schoolId);
              if (school) {
                application.step1Enabled = school.step1Enabled;
                application.absIntakeId = school.absIntakeId;
              }
            }
            selectModalOpened = false;
          });
          return modal.present();
        }
      },
      filteredGroupedUpcomingEvents: () => {
        const showOnlyFeaturedEvent = featuredEvent.value?.name.toLowerCase().includes("band a");
        const res = {};
        for (const key in groupedUpcomingEvents.value) {
          const events: Session[] = groupedUpcomingEvents.value[key];
          for (const ev of events) {
            if (showOnlyFeaturedEvent && ev.id != featuredEvent.value?.id) {
              continue; // special handling for Band A workshop (not show others)
            }
            if (ev.targetDSEYears.length == 0 || ev.targetDSEYears.includes(application.yearDSE)) {
              (res[key] = res[key] || []).push(ev);
            }
          }
        }
        return res;
      },


      // University Students
      allJobEXes, jobEXProgrammes,
      selectedJobEX, selectedJobEXIntakeEvents,

      // UCircle
      isUCircleApplicant,
      upcomingUCircleEvents,
      routeStudentGroup,

      // Teacher
      upcomingTeacherEvents,
      featuredService, prefilledServiceId, getLinkifiedText,
    }
  },
}
