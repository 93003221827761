
// Vue reactivity
import { computed, defineComponent, onMounted, ref } from 'vue';

// icons
import { close, checkmark, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonFooter, IonContent, IonButtons, IonButton, IonIcon,
        IonList, IonSearchbar, IonItem, IonLabel, IonInfiniteScroll, IonInfiniteScrollContent,
        IonCheckbox, IonChip,
        modalController } from '@ionic/vue';

import { utils } from '@/composables/utils';

export default defineComponent({
  name: 'SchoolSelectModal',
  props: ["schools", "showAllSchools", "title", "isMultiSelect", "prefilledSchoolIds"],
  components: { IonHeader, IonToolbar, IonTitle, IonFooter, IonContent, IonButtons, IonButton, IonIcon,
                IonList, IonSearchbar, IonItem, IonLabel, IonInfiniteScroll, IonInfiniteScrollContent,
                IonCheckbox, IonChip, },
  setup(props) {
    const searchKeyword = ref("");
    const isSearching = ref(false);
    const { infiniteScrollLoadData } = utils();

    const numOfVisibleItems = ref(50);
    const loadData = (ev: any, schools: any) => {
      infiniteScrollLoadData(ev, numOfVisibleItems, schools, 50);
    }

    const selectedSchools = ref<{ [key: string]: boolean }>({});
    const selectedSchoolIds = computed(() => (Object.keys(selectedSchools.value).filter(schoolId => selectedSchools.value[schoolId])))

    const closeModal = async (selectedSchool = "", schoolId = null) => {
      return await modalController.dismiss({ selectedSchool, schoolId, })
    };

    const filteredSchools = (schools: any) => {
      const skippedSchools = props.showAllSchools ? [] : ['demo', 'beacon1', 'beacon3', 'beacon4'];
      return schools.filter((sch: any) => {
        const schoolAbbr = sch.name.split(' ').map(str => str.charAt(0)).join('');
        const cleanedSchoolName = `${sch.nameShort} ${sch.name} ${sch.nameChi} ${schoolAbbr}`.replace(/[^a-z0-9]/gi, "").toLowerCase();
        const cleanedKeyword = searchKeyword.value.replace(/[^a-z0-9]/gi, "").toLowerCase();
        return !skippedSchools.includes(sch.id) && cleanedSchoolName.includes(cleanedKeyword);
      }).sort((a: any, b: any) => {
        const textA = a.nameShort.toUpperCase(), textB = b.nameShort.toUpperCase();
        if (textA == "") return 1;
        if (textB == "") return -1;
        return textA < textB ? -1 : (textA > textB ? 1 : 0); // put to back if empty
      });
    }

    onMounted(() => {
      const { prefilledSchoolIds } = props;
      if (prefilledSchoolIds) {
        prefilledSchoolIds.forEach(schoolId => {
          selectedSchools.value[schoolId] = true;
        })
      }
    })

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      close, checkmark,
      
      // variables
      searchKeyword, isSearching, numOfVisibleItems,
      selectedSchools, selectedSchoolIds,
      
      // methods
      closeModal, filteredSchools, loadData,
      confirmMultiSelect: async () => {
        return await modalController.dismiss({ selectedSchoolIds: selectedSchoolIds.value })
      }
    }
  }
});
